import { Flex, Text } from "@chakra-ui/react";
import { formatDateToHumanDate, formatDateToHumanTime } from "common/utils/formDateToHumanTime";
import { Icon } from "@chakra-ui/react";
import { FaCheck, FaUser } from "react-icons/fa6";

const ProgressIcon = ({ isBlurred, isCompleted, CardIcon, zIndex, className }) => {
  return (
    <span
      className={`items-center h-10 w-10 flex justify-center border border-black rounded-full ${zIndex} ${isCompleted ? 'bg-black text-white' : 'bg-slate-100'} ${isBlurred && 'border-slate-200'} ${className}`}
    >
      <Icon
        className={`text-lg ${isBlurred && 'text-slate-400'}`}
        as={isCompleted ? FaCheck : CardIcon || FaUser}
        boxSize={4}
      />
    </span>
  ) 
}

const LogItem = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-start flex-col mb-4'>
      <Flex className='w-full gap-2 justify-between items-center'>
        <Text className='text-xl font-bold capitalize'>
          {logItem.title}
        </Text>
        <Text className='text-base text-slate-500 min-w-fit mr-2'>
          {formatDateToHumanTime(logItem.timestamp)}
        </Text>  
      </Flex>                      
      <Text className='text-base'>
        {logItem.description}
      </Text>
    </Flex> 
  )
}

const LogList = ({ logs, RenderItem }) => {
  return (
    <Flex className='w-full flex-col gap-1 py-1 col-span-4'>
      {logs?.map((logItem, index) => (
        <>
          <Flex key={index} className='w-full gap-4 items-start'>
            <Flex className='flex-col h-full items-center'>
              <ProgressIcon
                CardIcon={FaCheck}
                className='px-4 py-4 mt-1'
              />
              {/* Divider between log items */}
              {index < logs?.length - 1 && (
                <div className='h-full min-h-[1rem] mt-2 flex w-10 justify-center'>
                  <div className='h-full border-l-2 border-slate-200' />
                </div>
              )}
            </Flex>
            {
              RenderItem ? (
                <RenderItem logItem={logItem} />
              ) : (
                <LogItem logItem={logItem} />
              )
            }                               
          </Flex>                  
        </>
      ))}
    </Flex>
  );
}

export default LogList;
