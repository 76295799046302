import React from 'react';
import { toast } from 'react-toastify';
import ErrorData from 'components/shared/error-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import { Formik } from 'formik';
import { toDatePickerFormat } from 'utils/date';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import SelectInput from 'common/components/select-input';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetClaimQuery,
  useUpdateClaimMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { claimStatusEnum, claimTypeEnum, claimPaymentPreferenceEnum } from 'utils/enums';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import Input from 'components/shared/input';
import TextAreaInput from 'components/shared/text-area-input';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';
import { claimStatusOptions } from 'utils/selectOptions';

const ClaimEdit = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();

  const [
    updateClaim,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateClaimMutation();
  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }  
  // TODO Have loading screen, or something better if no data available
  if (!claimData) {
    return <LoadingSpinner />;
  }

  const {
    userId: userData,
    planId: planData,
  } = claimData;

  const {
    status: claimStatus,
  } = claimData;

  return (
    <Formik
      onSubmit={(values) => {
        let submissionValues = {
          id: claimId,
          status: values.status,          
        }

        if (values.review.reason) {
          submissionValues.review = {            
            reason: values.review.reason,
          }
        }

        if (values.review.dates.moveOutStart && values.review.dates.moveOutEnd) {
          submissionValues.review = {
            ...submissionValues.review,
            dates: {
              moveOutStart: values.review.dates.moveOutStart,
              moveOutEnd: values.review.dates.moveOutEnd,
            }
          }
        }

        if (values.submission.confirmedDate) {
          submissionValues.submission = {
            confirmedDate: values.submission.confirmedDate,
          }
        }

        if (values.memberPayment.deductible !== undefined && values.memberPayment.nonEligibleCharges !== undefined) {
          submissionValues.memberPayment = {
            deductible: values.memberPayment.deductible,
            nonEligibleCharges: values.memberPayment.nonEligibleCharges,
          };
        }

        console.log("submissionValues: ", submissionValues);

        updateClaim(submissionValues)
          .unwrap()
          .then((data) => {
            navigate(`/dashboard/claims/${data?.id}`);
          })
          .catch((e) => {
            toast.error(e?.data?.msg || 'Update Failed');
          });
      }}
      initialValues={{
        status: claimStatus,
        submission: {
          confirmedDate: claimData?.submission?.confirmedDate && toDatePickerFormat(claimData?.submission?.confirmedDate),
        },
        review: {
          dates: {
            moveOutStart: claimData?.review?.dates?.moveOutStart && toDatePickerFormat(claimData?.review?.dates?.moveOutStart),
            moveOutEnd: claimData?.review?.dates?.moveOutEnd && toDatePickerFormat(claimData?.review?.dates?.moveOutEnd),
          },          
          reason: claimData?.review?.reason,
        },
        memberPayment: {
          deductible: claimData?.memberPayment?.deductible,
          nonEligibleCharges: claimData?.memberPayment?.nonEligibleCharges,
        },
      }}
        >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        dirty: isDirty,
        values,
        setFieldValue,
      }) => {
        return (
          <DashboardWrapperLayout title={`Claim ${claimId.toUpperCase()}`}>
            {/* Loading Animation */}
            {(isUpdateLoading || isUpdateFetching) && (
              <LoadingSpinner center={true} />
            )}

            {/* Main Content */}
            <div className='flex flex-col w-full gap-6'>

              {/* Combined breadcrumb + edit toggle header */}
              <div className='w-full flex gap-3 justify-between'>
                {/* breadcrumb */}
                <div className='flex gap-3 items-center'>
                  <Link
                    to='/dashboard/home'
                    className='font-normal text-base text-[#CED0CE]'
                  >
                    Dashboard
                  </Link>
                  <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                  <Link
                    to='/dashboard/claims'
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    Claims
                  </Link>
                  <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                  <Link
                    to={`/dashboard/claims/${claimId}`}
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    {claimId.toUpperCase()}
                  </Link>
                  <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
                  <Link
                    to={`/dashboard/claims/${claimId}/edit`}
                    className='text-[#191923] text-base font-normal'
                  >
                    Edit
                  </Link>
                </div>

                <Toggle
                  selected={'edit'}
                  editPath={`/dashboard/claims/${claimId}/edit`}
                  viewPath={`/dashboard/claims/${claimId}`}
                />
              </div>

              {/* Action Buttons */}
              <div className='w-full flex gap-2 justify-end items-center'>
                <Button
                  disabled={!isValid || !isDirty}
                  onClick={handleSubmit}
                  title='Save Changes'
                  wFull={false}
                  className='px-6'
                />                
              </div>

              {/* Editable Fields */}
              <InfoCard title='Change Claim Status'>
                <InfoGrid>
                  <InfoField title='Status'>
                    <SelectInput
                      options={claimStatusOptions}
                      placeholder='Select Status'
                      name='status'
                      value={claimStatusOptions.find(
                        (status) => status.value === values.status
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('status', selectedOption?.value || '');
                      }}
                      onBlur={handleBlur}
                    />
                  </InfoField>                  
                </InfoGrid>

                {/* Approved Dates */}
                {/* Only show if status is set to review, resolved, confirmed, completed */}
                {
                  [claimStatusEnum.inReview].includes(claimStatus) && (
                    <>
                      <InfoGrid>
                        <InfoField title='Approved Earliest Move Out Date'>
                          <Input
                            type='date'
                            value={values?.review?.dates?.moveOutStart}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='review.dates.moveOutStart'
                          />
                        </InfoField>
                        <InfoField title='Approved Latest Move Out Date'>
                          <Input
                            type='date'
                            value={values?.review?.dates?.moveOutEnd}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='review.dates.moveOutEnd'
                          />
                        </InfoField>
                        <InfoField title='Deductible'>
                          <Input
                            type='number'
                            placeholderText='Enter Number'
                            value={values?.memberPayment?.deductible}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='memberPayment.deductible'
                            prefix='$'
                            suffix={planData.lease.currency}
                          />
                        </InfoField>
                        <InfoField title='Non-Eligible Charges'>
                          <Input
                            type='number'
                            value={values?.memberPayment?.nonEligibleCharges}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='memberPayment.nonEligibleCharges'
                            prefix='$'
                            suffix={planData.lease.currency}
                          />
                        </InfoField>
                      </InfoGrid>

                      <InfoGrid>                  
                        <InfoField title='Reason' className='col-span-4'>
                          <TextAreaInput
                            placeholderText='Reason for Review'
                            value={values?.review?.reason}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='review.reason'
                            className='min-h-[200px]'
                          />
                        </InfoField>
                      </InfoGrid>                   
                    </>
                  )}
              </InfoCard>

              {/* Viewable Fields for Reference */}
              <InfoCard title='Submitted Information'>
                {/* shared information for all claim types */}
                <InfoGrid>
                  <InfoField title='Claim Number'>{claimData?.id}</InfoField>
                  <InfoField title='Plan Number'>
                    {planData?.id}
                  </InfoField>
                  <InfoField title='Lease'>
                    {planData?.lease?.address?.streetAddress1}, {planData?.lease?.address?.city}
                  </InfoField>
                  <InfoField title='Claim Type'>
                    {claimData?.claimType === claimTypeEnum.CANCEL_ANYTIME ? 'Cancel Lease' : 'Early Move Out'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Earliest Move Out Date'>
                    {formatDateToHumanDate(claimData?.submission?.moveOutStart)}
                  </InfoField>
                  <InfoField title='Latest Move Out Date'>
                    {formatDateToHumanDate(claimData?.submission?.moveOutEnd)}
                  </InfoField>
                  <InfoField title='Payment Preference'>
                    {claimData?.submission?.paymentPreference === claimPaymentPreferenceEnum.DIRECT ? 'Direct' : 'Reimbursement'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Reason for Move Out' className='col-span-4'>
                    {claimData?.submission?.moveReason}
                  </InfoField>
                </InfoGrid>
              </InfoCard>
            </div>
          </DashboardWrapperLayout>
        );
      }}
    </Formik>
  );
};

export default ClaimEdit;
