import React, { useState } from 'react';
import TextArea from 'components/shared/text-area-input';
import { toast } from 'react-toastify';
import ErrorData from 'components/shared/error-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import { Formik } from 'formik';
import { toDatePickerFormat, toDDMMYYYY } from 'utils/date';
import { IoMdSave } from 'react-icons/io';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import DocumentPreview from 'common/components/document-preview';
import SelectInput from 'common/components/select-input';
import StatusLabel from 'components/claims/status-label';
import ClaimType from 'components/claims/claim-type';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetClaimQuery,
  useUpdateClaimMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { claimStatusEnum, claimTypeEnum, claimPaymentPreferenceEnum } from 'utils/enums';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import Input from 'components/shared/input';
import TextAreaInput from 'components/shared/text-area-input';
import { formatDateToHumanDate, formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';
import LogPaymentModal from 'components/claims/log-payment-modal';
import LogList from 'components/shared/admin-log';
import FormatAsCurrency from 'common/utils/formatAsCurrency';
import LogConfirmationModal from 'components/claims/log-confirmation-modal';

// Set up rendering for progress log and payments log
const ProgressLog = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-center flex-col'>
      <Flex className='w-full gap-1 flex-col items-start'>
        <Text className='text-xl font-bold capitalize'>
          {logItem.status}
        </Text>
        <Text className='text-base text-slate-500 min-w-fit mr-2'>
          {formatDateToHumanTime(logItem.updatedAt)}
        </Text>  
      </Flex>
    </Flex>
  )
}

const PaymentLog = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-start flex-col mb-2'>
      <Flex className='w-full gap-2 justify-between items-center'>
        <Text className='text-xl font-bold capitalize'>
          {FormatAsCurrency(logItem.amount, logItem.currency)} {logItem.currency || 'USD'}
        </Text>
        <Text className='text-base text-slate-500 min-w-fit mr-2'>
          {formatDateToHumanDate(logItem.date)}
        </Text>  
      </Flex>                      
      <Flex className='w-full gap-2 justify-between items-center'>
        <Text className='text-base'>
        <span className='capitalize'>{logItem.paymentMethod}</span> Payment to <span className='capitalize'>{logItem.recipient}</span>
        </Text>
        {logItem.receipt && (
          <button
            onClick={
              () => {
                if (logItem.receipt) {
                  window.open(logItem.receipt, '_blank');
                } else {
                  toast.error('No receipt available');
                }
              }
            }
            type='button'
            className='rounded border border-[#CED0CE] py-0.5 px-2 text-sm font-medium cursor-pointer hover:bg-gray-200'
          >
            Receipt
          </button>
        )}
      </Flex>
    </Flex>
  )
}

const ConfirmationLog = ({ logItem }) => {
  return (
    <Flex className='w-full gap-1 items-start flex-col mb-2'>
      <Flex className='w-full gap-2 justify-between items-center'>
        <Text className='text-xl font-bold capitalize'>
          {logItem.title}
        </Text>
        <Text className='text-base text-slate-500 min-w-fit mr-2'>
          {formatDateToHumanDate(logItem.timestamp)}
        </Text>  
      </Flex>                      
      <Flex className='w-full gap-2 justify-between items-center'>
        {logItem.fileUrl && (
          <button
            onClick={
              () => {
                if (logItem.fileUrl) {
                  window.open(logItem.fileUrl, '_blank');
                } else {
                  toast.error('No documentation available');
                }
              }
            }
            type='button'
            className='rounded border border-[#CED0CE] py-0.5 px-2 text-sm font-medium cursor-pointer hover:bg-gray-200'
          >
            View Documentation
          </button>
        )}
      </Flex>
    </Flex>
  )
}

const ClaimView = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();
  const [logModal, setLogModal] = useState({ open: false, id: null });
  const [confirmationModal, setConfirmationModal] = useState({ open: false, id: null });

  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  // Turn confirmation into an array sorted by timestamp for ease of rendering
  let confirmationArray = Object.entries(claimData?.confirmation || {}).map(([key, value]) => ({
    title: key === 'vacated' ? 'Unit Vacated' : key === 'current' ? 'Member Current' : 'Unit Re-Listed',
    timestamp: value.timestamp,
    fileUrl: value.fileUrl,
  }));
  
  // Sort only if the array is not empty
  if (confirmationArray.length > 0) {
    confirmationArray.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }

  // Conditional Loads

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  // TODO Have loading screen, or something better if no data available
  if (!claimData) {
    return <LoadingSpinner />;
  }

  const { userId: userData, planId: planData } = claimData;  
  const { status: claimStatus } = claimData; 

  return (
    <DashboardWrapperLayout title={`Claim ${claimId.toUpperCase()}`}>
      {/* Main Content */}
      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to='/dashboard/claims'
              className='text-[#CED0CE] text-base font-normal'
            >
              Claims
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to={`/dashboard/claims/${claimId}`}
              className='text-[#191923] text-base font-normal'
            >
              {claimId.toUpperCase()}
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/claims/${claimId}/edit`}
            viewPath={`/dashboard/claims/${claimId}`}
          />
        </div>

        {/* Action Buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          {/* Only show log button if status is confirmed */}
          {claimStatus === claimStatusEnum.Confirmed && (
            <>
              <Button
                title='Log Compensation Payment'
                theme='primary'
                wFull={false}
                onClick={() => {
                  setLogModal({ open: true, id: claimId });
                }}
              />
              <Button
                title='Log Claim Confirmation'
                theme='primary'
                wFull={false}
                onClick={() => {
                  setConfirmationModal({ open: true, id: claimId });
                }}
              />
            </>
          )}
          <Button
            onClick={() => {
              navigate(`/dashboard/members/${userData.id}`);
            }}
            title='View Member Details'
            theme='secondary'
            wFull={false}
            className='px-6'
          />
          <Button
            onClick={() => {
              navigate(`/dashboard/plans/${planData.id}`);
            }}
            title='View Plan Details'
            theme='secondary'
            wFull={false}
            className='px-6'
          />
        </div>

        {/* Log Payment Modal */}
        {logModal.open && (
          <LogPaymentModal
            claimId={claimId}
            onCancel={() => {
              setLogModal({ open: false, id: null });
            }}
          />
        )}

        {/* Log Confirmation Modal */}
        {confirmationModal.open && (
          <LogConfirmationModal
            claimId={claimId}
            onCancel={() => {
              setConfirmationModal({ open: false, id: null });
            }}
          />
        )}

        <div className='grid grid-cols-3 gap-4 items-start'>
          <InfoCard title='Claim Progress'>
            <Flex className='p-4'>
              <LogList logs={claimData?.statusChanges} RenderItem={ProgressLog}/>
            </Flex>
          </InfoCard>

          <InfoCard title='Claim Payments'>
            <InfoGrid small>
              <InfoField title='Total Payments'>
                {FormatAsCurrency(claimData?.claimPayments?.reduce((acc, curr) => acc + curr.amount, 0), claimData?.planId?.lease?.currency)} {claimData?.planId?.lease?.currency || 'USD'}
              </InfoField>
              <InfoField title='Months Covered'>
                {claimData?.claimPayments?.length} months
              </InfoField>
            </InfoGrid>
            {claimData?.claimPayments?.length > 0 && (
              <Flex className='p-4'>
                <LogList logs={claimData?.claimPayments} RenderItem={PaymentLog}/>
              </Flex>
            )}
          </InfoCard>

          <InfoCard title='Claim Confirmation'>
            <Flex className='p-4'>
              <LogList logs={confirmationArray} RenderItem={ConfirmationLog}/>
            </Flex>
            {/* <InfoGrid small>
              <InfoField title='Unit Vacated' className='col-span-2'>
                {formatDateToHumanDate(claimData?.confirmation?.vacated?.timestamp)}
              </InfoField>
              <InfoField title='Documentation' className='col-span-2'>
                {claimData?.confirmation?.vacated?.fileUrl ? (
                <DocumentPreview
                  label='Uploaded documentation'
                  url={claimData?.confirmation?.vacated?.fileUrl}
                />
                ) : ('No documentation')}
              </InfoField>
            </InfoGrid>
            <InfoGrid small>
              <InfoField title='Member Account Current' className='col-span-2'>
                {formatDateToHumanDate(claimData?.confirmation?.current?.timestamp)}
              </InfoField>
              <InfoField title='Documentation' className='col-span-2'>
                {claimData?.confirmation?.current?.fileUrl ? (
                <DocumentPreview
                  label='Uploaded documentation'
                  url={claimData?.confirmation?.current?.fileUrl}
                />
                ) : ('No documentation')}
              </InfoField>
            </InfoGrid>
            <InfoGrid small>
              <InfoField title='Unit Re-Listed' className='col-span-2'>
                {formatDateToHumanDate(claimData?.confirmation?.relisted?.timestamp)}
              </InfoField>
              <InfoField title='Documentation' className='col-span-2'>
                {claimData?.confirmation?.relisted?.fileUrl ? (
                <DocumentPreview
                  label='Uploaded documentation'
                  url={claimData?.confirmation?.relisted?.fileUrl}
                />
                ) : ('No documentation')}
              </InfoField>
            </InfoGrid> */}
          </InfoCard>
        </div>

        {/* Submitted Information */}
        <InfoCard title='Submitted Information'>
          {/* shared information for all claim types */}
          <InfoGrid>
            <InfoField title='Claim Number'>{claimData?.id}</InfoField>
            <InfoField title='Plan Number'>
              {planData?.id}
            </InfoField>
            <InfoField title='Lease'>
              {planData?.lease?.address?.streetAddress1}, {planData?.lease?.address?.city}
            </InfoField>
            <InfoField title='Claim Type'>
              {claimData?.claimType === claimTypeEnum.CANCEL_ANYTIME ? 'Cancel Lease' : 'Early Move Out'}
            </InfoField>
          </InfoGrid>

          <InfoGrid>
            <InfoField title='Earliest Move Out Date'>
              {formatDateToHumanDate(claimData?.submission?.moveOutStart)}
            </InfoField>
            <InfoField title='Latest Move Out Date'>
              {formatDateToHumanDate(claimData?.submission?.moveOutEnd)}
            </InfoField>
            <InfoField title='Payment Preference'>
              {claimData?.submission?.paymentPreference === claimPaymentPreferenceEnum.DIRECT ? 'Direct' : 'Reimbursement'}
            </InfoField>
          </InfoGrid>

          <InfoGrid>
            <InfoField title='Reason for Move Out' className='col-span-4'>
              {claimData?.submission?.moveReason}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Status and Results */}
        <InfoCard title='Claim Review'>
          <InfoGrid>
            <InfoField title='Status'>
              <StatusLabel status={claimStatus} />
            </InfoField>            
          </InfoGrid>
          {claimData?.review?.dates?.moveOutStart && (
          <InfoGrid>
            <InfoField title='Approved Earliest Move Out Date'>
              {formatDateToHumanDate(claimData?.review?.dates?.moveOutStart)}
            </InfoField>
            <InfoField title='Approved Latest Move Out Date'>
              {formatDateToHumanDate(claimData?.review?.dates?.moveOutEnd)}
            </InfoField>
            <InfoField title='Confirmed Move Out Date'>
              {formatDateToHumanDate(claimData?.submission?.confirmedDate)}
            </InfoField>
          </InfoGrid>
          )}
          <InfoGrid>
            <InfoField title='Review Notes' className='col-span-4'>
              {claimData?.review?.reason}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        <InfoCard title='Member Payment'>
          <InfoGrid>
            <InfoField title='Member Payment ID'>              
              <Link
                onClick={() => {
                  let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${claimData?.memberPayment?.stripeId}`;
                  window.open(url, '_blank');
                }}
                className='underline hover:opacity-50'
              >
                {claimData?.memberPayment?.stripeId}
              </Link>
            </InfoField>
            <InfoField title='Deductible'>
              ${claimData?.memberPayment?.deductible} {planData?.lease?.currency}
            </InfoField>
            <InfoField title='Non-Eligible Charges'>
              ${claimData?.memberPayment?.nonEligibleCharges} {planData?.lease?.currency}
            </InfoField>
          </InfoGrid>
        </InfoCard>        
      </div>
    </DashboardWrapperLayout>
  );
};

export default ClaimView;
