import { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDeletePropertyMutation } from 'redux/api/ciosAdminApi/ciosAdminApi';
import DeleteModal from 'common/components/delete-modal';
import {
  BodyRow,
  HeaderCell,
  HeaderRow,
  Table,
  TableBody,
  TableCell,
} from 'common/components/data-table';
import SortButton from 'common/components/sort-button';
import { formatPhoneNumber } from 'react-phone-number-input';
import { StatusLabel } from 'components/properties/status-label';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { HamburgerIcon, ViewIcon } from '@chakra-ui/icons';

// NOTES:
// Delete modal isn't working yet, so the button has been commented out for now

const PropertiesTable = ({
  properties,
  sortVal,
  setSort,
  isRounded = true,
}) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteProperty,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeletePropertyMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  return (
    <Table>
      {(isUpdateLoading || isUpdateFetching) && <LoadingSpinner />}
      {deleteModal.open && (
        <DeleteModal
          handleDelete={() => {
            deleteProperty({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/properties');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <HeaderRow
        isRounded={isRounded}
        gridLayout='grid-cols-[1fr_1fr_1.5fr_140px_180px]'
      >
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='name'
            label='Property Name'
          />
        </HeaderCell>
        <HeaderCell>Contact Info</HeaderCell>
        <HeaderCell>Address</HeaderCell>
        <HeaderCell>Default Rate</HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {properties?.map((property, index) => (
          <BodyRow
            gridLayout='grid-cols-[1fr_1fr_1.5fr_140px_180px]'
            key={index}
            onClick={() => {
              navigate(`/dashboard/properties/${property.id}`);
            }}
          >
            {/* Name and ID */}
            <TableCell
              top={property?.name}
              bottom={property?.id}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   return false;
              // }}
            />

            {/* Contact Info (Email + Phone) */}
            <TableCell
              top={property?.email}
              topLink={`mailto:${property?.email}`}
              bottom={formatPhoneNumber(property?.phone)}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            />

            {/* Address */}
            {/* Placeholder when address is undefined (shouldn't happen) */}
            {property?.address?.streetAddress1 ? (
              <TableCell
                top={`${property?.address.streetAddress1}${
                  property?.address.streetAddress2 && ', '
                }${property?.address.streetAddress2 || ''}`}
                bottom={`${property?.address.city}, ${
                  property?.address.state || ''
                } ${property?.address.postalCode || ''}`}
              />
            ) : (
              <TableCell top='-' bottom='-' />
            )}

            {/* Default Rate */}
            <TableCell
              top={`${property?.defaultRate ? ((property?.defaultRate * 100).toFixed(2)) : '- '}%`}
              bottom={`${property?.riskScore ? (property?.riskScore) : '- '}/10 Risk Score`}
            />

            {/* Status */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel status={property?.status} size='sm' />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/properties/${property?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Property Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/properties/${property?.id}/edit`);
                    }}
                    icon={<BiEdit />}
                  >
                    Edit Property Details
                  </MenuItem>
                  {/* <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModal({ open: true, id: property.id });
                    }}
                    icon={<AiOutlineDelete />}
                  >
                    Delete Property
                  </MenuItem> */}
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PropertiesTable;
