import { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiCopy, BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { 
  useDeleteTenantMutation,
  useClaimAccountTenantReqMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import DeleteModal from 'common/components/delete-modal';
import {StatusLabel} from 'components/members/status-label';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Icon,
  ButtonGroup,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  ViewIcon,
  AddIcon,
  RepeatIcon,
  LockIcon,
  CloseIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import SortButton from 'common/components/sort-button';
import { toDDMMYYYY } from 'utils/date';
import {Table, HeaderRow, HeaderCell, TableBody, BodyRow, TableCell} from 'common/components/data-table';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { StatusLabel as DocumentStatusLabel } from 'components/documents/status-label';
import { StatusLabel as VerificationStatusLabel } from 'components/verification/status-label';

function ClaimAccountButton({ userId, emailStatus }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');

  return (
    <div className={`relative ${emailStatus && 'hidden'}`}>
      <MenuItem
        icon={<RepeatIcon />}
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
      >
        Send Activation link
      </MenuItem>
      {claimUrl &&
        <MenuItem
          icon={<BiCopy />}
          onClick={
            navigator.clipboard.writeText(claimUrl || 'Copy Activation Link').then(
              () => {
          
              },
              (err) => {
                console.error('Failed to copy text: ', err);
              }
            )
          }
        >
          Copy Activation Link
        </MenuItem>
      }
    </div>
  );
}

const MembersTable = ({ members, setSort, sortVal, isRounded=true }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteTenant,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteTenantMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  return (
    <Table>
      {(isUpdateLoading || isUpdateFetching) && <LoadingSpinner />}
      {deleteModal.open && (
        <DeleteModal
          handleDelete={() => {
            deleteTenant({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/members');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <HeaderRow gridLayout='grid-cols-[220px_1fr_120px_120px_120px_170px]' isRounded={isRounded}>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='firstName'
            label='Name'
          />
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='email'
            label='Contact'
          />
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='createdAt'
            label='Created'
          />
        </HeaderCell>
        <HeaderCell>
          Identity
        </HeaderCell>
        <HeaderCell>
          Email
        </HeaderCell>
        <HeaderCell>
          Status
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {members?.map((member, index) => (
          <BodyRow
            gridLayout='grid-cols-[220px_1fr_120px_120px_120px_170px]'
            key={index}
            onClick={() => {
              navigate(`/dashboard/members/${member.id}`);
            }}
          >
            {/* Name and ID */}
            <TableCell
              top={`${member?.firstName} ${member?.lastName}`}
              bottom={member?.id}
            />

            {/* Email and Phone Number */}
            <TableCell
              top={member?.email}
              bottom={formatPhoneNumber(member?.phone)}
              topLink={`mailto:${member?.email}`}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            />

            {/* Created and Signup Code */}
            <TableCell
              top={formatDateToHumanDate(member?.createdAt)}
              bottom={member?.signupCode ? member?.signupCode : '-'}
            />

            {/* ID Verification */}
            <TableCell>
              <DocumentStatusLabel status={member?.idVerification} size='sm' />
            </TableCell>

            {/* Email Verification */}
            <TableCell>
              <VerificationStatusLabel status={member?.emailConfirmed} size='sm' />
            </TableCell>

            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel status={member.status} size='sm' />
              <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                    variant='outline'
                  />
                  <MenuList>
                    <MenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/members/${member?.id}`);
                      }}
                      icon={<ViewIcon />}
                    >
                      View Member
                    </MenuItem>

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/members/${member?.id}/edit`);
                      }}
                      icon={<ViewIcon />}
                    >
                      Edit Member
                    </MenuItem>

                    <MenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/dashboard/plans/new?userID=${member?.id}`);
                      }}
                      icon={<AddIcon />}
                    >
                      Add Plan
                    </MenuItem>

                    <ClaimAccountButton
                      userId={member?.id}
                      emailStatus={member?.emailConfirmed}
                    />

                    {/* Removed password reset function until implemented in admin api safely */}
                    {/* <MenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(``);
                      }}
                      icon={<LockIcon />}
                    >
                      Send Password Reset Link
                    </MenuItem> */}

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModal({ open: true, id: member.id });
                      }}
                      icon={<CloseIcon />}
                    >
                      Deactivate member
                    </MenuItem>
                  </MenuList>
                </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MembersTable;
