import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {},
    (err) => {
      console.error('Failed to copy text: ', err);
    }
  );
};

const CopyLink = ({ text, title }) => {
  return (
    <Link
      onClick={() => {
        copyToClipboard(text);
        toast.success(`Copied ${title || 'text'} to clipboard`);
      }}
      className='underline hover:opacity-50'
    >
      {text}
    </Link>
  )
}

export default CopyLink;
