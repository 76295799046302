import { useState, useEffect } from 'react';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import DeleteModal from 'common/components/delete-modal';
import { Link, useParams } from 'react-router-dom';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import EmptyData from 'components/shared/empty-data';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'utils/isEmpty';
import {
  useGetPropertyQuery,
  useDeletePropertyMutation,
  useFindPlansQuery,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Toggle from 'components/shared/toggle';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import RentalType from 'components/properties/rental-type';
import { StatusLabel } from 'components/properties/status-label';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';
import CopyLink from 'common/components/copy-link';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

const View = () => {
  const navigate = useNavigate();
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };
  const { propertyId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortVal, setSort] = useState('-createdAt');

  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetPropertyQuery(propertyId);
  const [
    deleteProperty,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
    // { isLoading: updatePlanLoading },
  ] = useDeletePropertyMutation();

  useEffect(() => {
    console.log('Property data loaded:', propertyData);
  }, [propertyData]);

  let plansData = propertyData?.plans || [];

  // Conditional Returns

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }  

  if (!propertyData) return <LoadingSpinner />;

  return (
    <DashboardWrapperLayout title={propertyData?.name || 'Property Details'}>
      {/* Loading Animation */}
      {(isUpdateLoading ||
        isUpdateFetching ||
        isGetFetching ||
        isGetLoading) && <LoadingSpinner />}

      {/* Un-used / not-yet-implemented delete function */}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteProperty({ id: propertyId })
              .unwrap()
              .then(() => {
                navigate('/dashboard/properties');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}

      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to='/dashboard/properties'
              className='text-[#CED0CE] text-base font-normal'
            >
              Properties{' '}
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to={`/dashboard/properties/${propertyData?.id}`}
              className='text-[#CED0CE] text-base font-normal'
            >
              {propertyData?.id.toUpperCase()}
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
            <Link
              to={`/dashboard/properties/${propertyData?.id}`}
              className='text-[#191923] text-base font-normal'
            >
              View{' '}
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/properties/${propertyData?.id}/edit`}
            viewPath={`/dashboard/properties/${propertyData?.id}`}
          />
        </div>

        {/* Action buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          <Button
            onClick={() => {
              navigate(`/dashboard/plans/new?property=${propertyData?.id}`);
            }}
            title='Add Plan'
            wFull={false}
            className='px-6'
          />
          {propertyData?.owner && (
            <Button
              onClick={() => {
                navigate(`/dashboard/owners/${propertyData?.owner?.id}`);
              }}
              title='Company Details'
              wFull={false}
              className='px-6'
              theme='secondary'
            />
          )}
          {/* Delete button commented out for now */}
          {/* <Button
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            Icon={AiOutlineDelete}
            theme='secondary'
            title='Delete Property'
            wFull={false}
            className='px-6'
          /> */}
        </div>

        {/* Property Details Table */}
        <InfoCard title='Property Information'>
          <InfoGrid>
            <InfoField title='Name'>{propertyData?.name}</InfoField>
            <InfoField title='Status'>
              <StatusLabel status={propertyData?.status} />
            </InfoField>
            <InfoField title='ID' className='col-span-2 uppercase'>
              <CopyLink text={propertyData?.id} title='Property ID' />
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Contact Name'>{propertyData?.contactName}</InfoField>
            <InfoField title='Contact Email'>{propertyData?.email}</InfoField>
            <InfoField title='Contact Phone'>
              {formatPhoneNumber(propertyData?.phone)}
            </InfoField>
            <InfoField title='Website'>{propertyData?.website}</InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Cancel Anytime Default Rate'>
              {propertyData?.cancelAnytimeRate ? `${(propertyData?.cancelAnytimeRate * 100).toFixed(2)}%` : 'No Default Rate Set'}
            </InfoField>
            <InfoField title='Leave Anytime Default Rate'>
              {propertyData?.leaveAnytimeRate ? `${(propertyData?.leaveAnytimeRate * 100).toFixed(2)}%` : 'No Default Rate Set'}
            </InfoField>
            <InfoField title='Risk Score'>
              {`${propertyData?.riskScore || '- '} Risk Score`}
            </InfoField>
            <InfoField title='Property Type'>
              <RentalType rentalType={propertyData?.kind} />
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Street Address 1' className={propertyData?.address?.streetAddress2 ? 'col-span-2' : 'col-span-4'}>
              {propertyData?.address.streetAddress1}
            </InfoField>
            {propertyData?.address.streetAddress2 && (
              <InfoField title='Street Address 2' className='col-span-2'>
                {propertyData?.address.streetAddress2}
              </InfoField>
            )}
            <InfoField title='City'>{propertyData?.address.city}</InfoField>
            {propertyData?.address.state && (
              <InfoField title='State'>{propertyData?.address.state}</InfoField>
            )}
            <InfoField title='Postal Code'>
              {propertyData?.address.postalCode}
            </InfoField>
            <InfoField title='Country'>
              {propertyData?.address.country}
            </InfoField>
          </InfoGrid>

          {/* Notes */}
          {propertyData?.notes && (
            <InfoGrid>
              <InfoField title='Notes' className='col-span-4'>
                {propertyData?.notes}
              </InfoField>
            </InfoGrid>
          )}
        </InfoCard>

        {/* Lease Defaults */}
        <InfoCard title='Property-Wide Lease Defaults'>
          <InfoGrid>
            <InfoField title='Default Notice Period'>
              {`${propertyData?.propertyDefaults?.terminationPolicy?.noticePeriod} days` || 'No Default Notice Period'}
            </InfoField>
            <InfoField title='Default Termination Penalty'>
              {FormatAsCurrency(propertyData?.propertyDefaults?.terminationPolicy?.penalty, propertyData?.paymentInformation?.currency) || 'No Default Penalty'}
            </InfoField>
            <InfoField title='Default Lease Buyout'>
              {propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths ? `${propertyData?.propertyDefaults?.terminationPolicy?.buyoutMonths} months` : 'No Default Buyout'}
            </InfoField>
            <InfoField title='Furnished by Default?'>
              {propertyData?.propertyDefaults?.furnished ? 'Yes' : 'No'}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Company Information */}
        {propertyData?.owner && (
          <InfoCard title='Company Information'>
            <InfoGrid>
              <InfoField title='Name'>{propertyData?.owner?.name}</InfoField>
              <InfoField title='Type'>
                {propertyData?.owner?.kind}
              </InfoField>
              <InfoField title='Status'>
                {propertyData?.owner?.status}
              </InfoField>
              <InfoField title='ID' className='uppercase'>
                <CopyLink text={propertyData?.owner?.id} title='Owner ID' />
              </InfoField>              
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Email'>{propertyData?.owner?.email}</InfoField>
              <InfoField title='Phone'>
                {formatPhoneNumber(propertyData?.owner?.phone)}
              </InfoField>
              <InfoField title='Website'>{propertyData?.owner?.website}</InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Rev Share Rate'>
                {`${
                  propertyData?.owner?.revShareRate
                    ? (propertyData?.owner?.revShareRate * 100).toFixed(2)
                    : '0'
                }%`}
              </InfoField>
              <InfoField title='Flat Commission'>
                {`$${
                  propertyData?.owner?.flatCommission
                    ? (propertyData?.owner?.flatCommission).toFixed(2)
                    : '0.00'
                } Per Plan`}
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Notes' className='col-span-4'>
                {propertyData?.owner?.notes}
              </InfoField>
            </InfoGrid>
          </InfoCard>
        )}
        
        {/* Payment Information */}
        {propertyData?.paymentInformation && (
          <InfoCard title='Payment Information'>
            <InfoGrid>
              <InfoField title='Currency'>
                {propertyData?.paymentInformation?.currency}
              </InfoField>
              <InfoField title='Beneficiary Name'>
                <CopyLink text={propertyData?.paymentInformation?.beneficiary} title='Beneficiary Name' />
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Beneficiary Account Number'>
                <CopyLink text={propertyData?.paymentInformation?.beneficiaryAccountNumber} title='Beneficiary Account Number' />
              </InfoField>
              <InfoField title='Beneficiary Routing Number (ABA)'>
                <CopyLink text={propertyData?.paymentInformation?.beneficiaryRoutingNumber} title='Beneficiary Routing Number' />
              </InfoField>
              <InfoField title='Beneficiary SWIFT Code'>
                <CopyLink text={propertyData?.paymentInformation?.beneficiarySwiftCode} title='Beneficiary SWIFT Code' />
              </InfoField>            
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Beneficiary Bank Name' className='col-span-4'>
                <CopyLink text={propertyData?.paymentInformation?.beneficiaryBankName} title='Beneficiary Bank Name' />
              </InfoField>
              <InfoField title='Street Address 1' className={propertyData?.paymentInformation?.beneficiaryBankAddress?.streetAddress2 ? 'col-span-2' : 'col-span-4'}>
                {propertyData?.paymentInformation?.beneficiaryBankAddress?.streetAddress1}
              </InfoField>
              {propertyData?.paymentInformation?.beneficiaryBankAddress?.streetAddress2 && (
                <InfoField title='Street Address 2' className='col-span-2'>
                  {propertyData?.paymentInformation?.beneficiaryBankAddress?.streetAddress2}
                </InfoField>
              )}
              <InfoField title='City'>{propertyData?.paymentInformation?.beneficiaryBankAddress?.city}</InfoField>
              {propertyData?.paymentInformation?.beneficiaryBankAddress?.state && (
                <InfoField title='State'>{propertyData?.paymentInformation?.beneficiaryBankAddress?.state}</InfoField>
              )}
              <InfoField title='Postal Code'>
                {propertyData?.paymentInformation?.beneficiaryBankAddress?.postalCode}
              </InfoField>
              <InfoField title='Country'>
                {propertyData?.paymentInformation?.beneficiaryBankAddress?.country}
              </InfoField>
            </InfoGrid>
          </InfoCard>
        )}        

        {/* Plans at this property */}
        <InfoCard title='Property Plans'>
          {/* Member Plan table */}
          {isEmpty(plansData) ? (
            <EmptyData />
          ) : (
            <PlansTable showMember={true} isRounded={false} plans={plansData} setSort={setSort} sortVal={sortVal} />
          )}
        </InfoCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
