import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ErrorData from 'components/shared/error-data';
import ClaimsTable from 'components/claims/claims-table';
import EmptyData from 'components/shared/empty-data';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'layouts/table-paginate-wrapper';
import LoadingSpinner from 'components/shared/loading-spinner';
import { isEmpty } from 'utils/isEmpty';
import { useFindClaimsQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Icon, Input } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';
import { claimStatusEnum } from 'utils/enums';
import CheckBox from 'components/shared/checkbox';

function ToggleArea({ statuses, setCurrentStatuses }) {
  const handleToggle = (status) => {
    if (statuses.includes(status)) {
      setCurrentStatuses(statuses.filter((s) => s !== status));
    } else {
      setCurrentStatuses([...statuses, status]);
    }
  };

  return (
    <Accordion
      allowMultiple
      className='flex w-full flex-col border border-slate-200 rounded-md overflow-hidden'
    >
      <AccordionItem className='border-none'>
        <h2>
          <AccordionButton className='bg-[#E1E6EF] hover:bg-slate-100 px-3'>
            <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
              Filter by Status
            </div>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel className='border-t py-4'>
          {/* Options */}
          <div className='grid grid-cols-4 gap-4'>
            {Object.keys(claimStatusEnum).map((key) => (
              <label key={key} className='flex items-center gap-2 text-sm'>
                <CheckBox
                  name={claimStatusEnum[key]}
                  onChange={() => handleToggle(claimStatusEnum[key])}
                  checked={statuses.includes(claimStatusEnum[key])}
                />
                <span className='capitalize'>
                  {claimStatusEnum[key]}
                </span>
              </label>
            ))}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

const View = () => {
  // DATA INITIALIZATION
  const pageSize = 25;  

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-statusChanges.updatedAt');
  const [textFilter, setTextFilter] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [statuses, setCurrentStatuses] = useState([]);

  // Update URL when filters change
  useEffect(() => {
    const params = {};
    if (statuses.length > 0) {
      params.statuses = statuses.join(',');
    }

    if (textFilter) {
      params.textFilter = textFilter;
    }
    setSearchParams(params);
  }, [statuses, textFilter, setSearchParams]);

  // Pull query variables from the URL to set the initial state
  useEffect(() => {
    const statusQuery = searchParams.get('statuses');
    if (statusQuery) {
      setCurrentStatuses(statusQuery.split(','));
    } else {
      setCurrentStatuses([
        claimStatusEnum.submitted,
        claimStatusEnum.inReview,
        claimStatusEnum.Resolved,
        claimStatusEnum.Confirmed,
      ]);
    }
  }, [searchParams]);

  const {
    data: claimsQuery,
    isError: isGetError,
    error: getError,
    isLoading: findClaimsLoading,
  } = useFindClaimsQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: claimsData, meta: claimsMeta } = claimsQuery || {
    data: [],
    meta: { count: 0 },
  };

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }  

  return (
    <DashboardWrapperLayout title='Claims'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-x-3  gap-y-1 items-center flex-wrap'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Claims{' '}
          </Link>
        </div>

        {/* Search is janky right now */}
        <div className='w-full flex gap-3 justify-between items-center'>
          {
            <Input
              placeholderText='Search'
              value={textFilter}
              onChange={(e) => {
                setTextFilter(e.target.value);
              }}
              name='textFilter'
              className='max-w-lg'
              placeholder='Search by claim number'
            />
          }
        </div>

        {/* Filter Area */}
        <ToggleArea
          statuses={statuses}
          setCurrentStatuses={setCurrentStatuses}
        />

        {/* table */}
        {findClaimsLoading && <LoadingSpinner center={true} />}
        {isEmpty(claimsData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={claimsMeta.count}
            pageSize={pageSize}
          >
            <ClaimsTable 
              claims={claimsData} 
              setSort={setSort}
              sortVal={sortVal}
            />
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
