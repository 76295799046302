import React from 'react';

const ModalBgLayout = ({ children }) => {
  return (
    <div className='fixed overflow-auto inset-0 left-0 top-0 w-full h-full flex items-center justify-center z-50 p-4 bg-black bg-opacity-50'>
      {/* wrapper */}
      <div className='bg-white rounded-lg shadow-xl p-6 w-full max-w-[36rem] m-auto'>
        {children}
      </div>
    </div>
  );
};

export default ModalBgLayout;
