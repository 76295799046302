import { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from 'components/shared/loading-spinner';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDeleteOwnerMutation } from 'redux/api/ciosAdminApi/ciosAdminApi';
import DeleteModal from 'common/components/delete-modal';
import StatusLabel from 'components/owners/status-label';
import { BodyRow, HeaderCell, HeaderRow, Table, TableBody, TableCell } from 'common/components/data-table';
import SortButton from 'common/components/sort-button';
import { formatPhoneNumber } from 'react-phone-number-input';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { HamburgerIcon, ViewIcon } from '@chakra-ui/icons';

const OwnersTable = ({ owners, sortVal, setSort }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteOwnerMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  return (
    <Table>
      {(isUpdateLoading || isUpdateFetching) && <LoadingSpinner />}
      {deleteModal.open && (
        <DeleteModal
          handleDelete={() => {
            deleteOwner({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/owners');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <HeaderRow isRounded={true} gridLayout='grid-cols-[1fr_1fr_170px_140px_180px]'>
        <HeaderCell sortColumn>
          <SortButton setSort={setSort} sortVal={sortVal} field='name' label='Company Name'/>
        </HeaderCell>
        <HeaderCell>
          Contact Info
        </HeaderCell>
        <HeaderCell>
          Commission
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='kind'
            label='Company Type'
          />
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='status'
            label='Status'
          />
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {owners?.map((owner, index) => (
          <BodyRow
            gridLayout='grid-cols-[1fr_1fr_170px_140px_180px]'
            key={index}
            onClick={() => {
              navigate(`/dashboard/owners/${owner.id}`);
            }}
          >
            {/* Name + ID */}
            <TableCell
              top={owner?.name}
              bottom={owner?.id}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   return false;
              // }}
            />

            {/* Email + Phone */}
            <TableCell
              top={owner?.email || '-'}
              topLink={`mailto:${owner?.email}`}
              bottom={formatPhoneNumber(owner?.phone) || '-'}
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            />
            
            {/* Commission Structure */}
            <TableCell 
              top={`${owner?.revShareRate ? ((owner?.revShareRate*100).toFixed(2)) : ('0')}% Rev Share`}
              bottom={`$${owner?.flatCommission ? ((owner?.flatCommission).toFixed(2)) : ('0.00')} Flat Commission`}
            />

            {/* Type */}
            <TableCell>
              <StatusLabel status={owner?.kind} size='sm' />
            </TableCell>

            {/* Status */}
            <TableCell
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel status={owner.status} size='sm' />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/owners/${owner?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Company Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/owners/${owner?.id}/edit`);
                    }}
                    icon={<BiEdit />}
                  >
                    Edit Company Details
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModal({ open: true, id: owner.id });
                    }}
                    icon={<AiOutlineDelete />}
                  >
                    Delete Company
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>
          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OwnersTable;
