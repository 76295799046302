import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFindOwnersQuery } from 'redux/api/ciosAdminApi/ciosAdminApi';
import OwnersTable from 'components/owners/owners-table';
import ErrorData from 'components/shared/error-data';
import Button from 'common/components/button';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'layouts/table-paginate-wrapper';
import LoadingSpinner from 'components/shared/loading-spinner';
import { isEmpty } from 'utils/isEmpty';
import EmptyData from 'components/shared/empty-data';
import Input from 'components/shared/input';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

function View() {
  const navigate = useNavigate();
  // DATA INITIALIZATION
  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');

  const {
    data: ownersQuery,
    isError: isGetError,
    error: getError,
    isLoading: findOwnersLoading,
  } = useFindOwnersQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
  });

  const { data: ownersData, meta: ownersMeta } = ownersQuery || {
    data: [],
    meta: { count: 0 },
  };

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  console.log('All owners?', ownersData);
  return (
    <DashboardWrapperLayout title='Companies'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-x-3  gap-y-1 items-center flex-wrap'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Companies{' '}
          </Link>
        </div>

        {/* Search + Create Button */}
        <div className='w-full flex gap-3 justify-between items-center'>
          <Input
            placeholderText='Search'
            value={textFilter}
            onChange={(e) => {
              setTextFilter(e.target.value);
            }}
            name='textFilter'
            className='max-w-lg'
          />
          <Button
            title='Add Company'
            wFull={false}
            className='px-8'
            onClick={() => navigate(`/dashboard/owners/new`)}
          ></Button>
        </div>

        {/* table */}
        {findOwnersLoading && <LoadingSpinner center={true} />}
        {isEmpty(ownersData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={ownersMeta.count}
          >
            <OwnersTable owners={ownersData} setSort={setSort} sortVal={sortVal}/>
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  );
}

export default View;
