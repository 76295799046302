export const DOTS = '...';
export const REQUESTED_PLAN = 1;
export const ACTIVE_PLAN = 2;

// Payment plans
// Matches user enums now
export const planPaymentEnum = {
  ONETIME: 'onetime',
  MONTHLY: 'monthly',
};

export const planStatusEnum = {
  created: 'created',
  pending: 'pending',
  review: 'review',
  active: 'active',
  complete: 'complete',
  rejected: 'rejected',
  cancelled: 'cancelled',
};

export const claimStatusEnum = {
  // CLAIMS
  Draft: 'draft',
  submitted: 'submitted',
  Completed: 'Completed',
  inReview: 'review',
  Expired: 'Expired',
  Denied: 'Denied',
  Approved: 'Approved',
  Confirmed: 'confirmed',
  Resolved: 'resolved',
  Cancelled: 'cancelled',
};

export const claimTypeEnum = {
  NEW_JOB: 'newJob',
  PURCHASED_HOME: 'homePurchase',
  OTHER_CLAIM: 'other',
};

export const paymentMethodEnum = {
  WIRE: "wire",
  ACH: "ach",
  ETRANSFER: "etransfer",  
  CHECK: "check",
}

export const stripeKYCEnum = {
  pending: 'pending',
  failed: 'error',
  verified: 'verified',
};

export const verificationStatusEnum = {
  VERIFIED: true,
  NOT_VERIFIED: false,
}

export const rentalTypeEnum = {
  MULTIFAMILY: 'Multifamily',
  STUDENT_HOUSING: 'Student Housing',
  OTHER: 'Other',
};

export const tenantStatusEnum = {
  NEW: 'New',
  INVITED: 'Invited',
  ACCEPTED: 'Accepted',
  DELETED: 'Deleted',
  VERIFIED: 'Verified',
  NOT_VERIFIED: 'Not Verified',
};

export const managerContractStatusEnum = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  TERMINATED: 'terminated',
  DID_NOT_RENEW: 'did-not-renew',
  ERROR: 'error',
};

export const documentStatusEnum = {
  NONE: 'none',
  UPLOADED: 'uploaded',
  REJECTED: 'rejected',
  VERIFIED: 'verified',
};

export const ownerStatusEnum = {
  ACTIVE: 'Active',
  DELETED: 'Deleted',
};

export const ownerKindEnum = {
  OWNER: 'Owner',
  MANAGER: 'Manager',
};

export const propertyStatusEnum = {
  INACTIVE: 'Inactive',
  OPTOUT: 'Opt-Out',
  OPTIN: 'Opt-In',
  REQUIRED: 'Required',
};

export const propertyKindEnum = {
  MULTIFAMILY: 'Multifamily',
  STUDENT: 'Student Housing',
  OTHER: 'Other',
};

export const MAX_DOCUMENT_SIZE = 20971520; // 20MB

export const claimPaymentPreferenceEnum = {
  DIRECT: 'direct',
  REIMBURSEMENT: 'reimbursement',
};

export const claimConfirmationTypeEnum = {
  VACATED: 'vacated',
  CURRENT: 'current',
  RELISTED: 'relisted',
};
