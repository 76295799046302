import React, { useState, useRef } from 'react';
import Toggle from 'components/shared/toggle';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import CopyTextComponent from 'components/shared/copy-text';
import StatusLabel from 'components/plans/status-label';
import { StatusLabel as MemberStatusLabel } from 'components/members/status-label';
import { StatusLabel as DocumentStatusLabel } from 'components/documents/status-label';
import { StatusLabel as VerificationStatusLabel } from 'components/verification/status-label';
import { StatusLabel as PropertyStatusLabel } from 'components/properties/status-label';
import DocumentPreview from 'common/components/document-preview';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  useClaimAccountTenantReqMutation,
  useGetPlanQuery,
  useUploadRequestUrlMutation,
  useImpersonateTenantMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import LoadingSpinner from 'components/shared/loading-spinner';
import Button from 'common/components/button';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight, FaStripe, FaStripeS } from 'react-icons/fa6';
import RentalType from 'components/properties/rental-type';
import { residencyStatusOptions } from 'utils/selectOptions';
import { ProgressBar, ProgressBarStep } from 'components/shared/progress-bar';
import { statusEnum } from 'common/utils/enums';
import { extractDocumentName } from 'common/utils/extractDocumentName';
import FormatAsCurrency from 'common/utils/formatAsCurrency';

function ClaimAccountArea({ userId, planId }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');
  return (
    <>
      <Button
        title='Send Activation Email'
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId, planID: planId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
        wFull={false}
        className='px-6'
      />
      {claimUrl && (
        <CopyTextComponent text={'Copy Activation Link'} copyText={claimUrl} />
      )}
    </>
  );
}

const PlanDetails = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
  } = useGetPlanQuery(planId);

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  // TODO Have loading screen, or something better if no data available
  if (!planQuery) {
    return <LoadingSpinner />;
  }

  const { data: planData } = planQuery || {
    data: {},
  };

  const propertyData = planData?.property;
  const hasCancelAnytime =
    planData?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    planData?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  return (
    <DashboardWrapperLayout title={`Plan ${planData.id.toUpperCase()}`}>
      {(false || false) && <LoadingSpinner center={true} />}
      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]' />
            <Link
              to='/dashboard/plans'
              className='text-[#CED0CE] text-base font-normal'
            >
              Plans
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]' />
            <Link
              to={`/dashboard/plans/${planData?.id}`}
              className='text-[#CED0CE] text-base font-normal'
            >
              {planData?.id.toUpperCase()}
            </Link>
            <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]' />
            <Link
              to={`/dashboard/plans/${planData?.id}`}
              className='text-[#191923] text-base font-normal'
            >
              View
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/plans/${planData?.id}/edit`}
            viewPath={`/dashboard/plans/${planData?.id}`}
          />
        </div>

        {/* Action buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          {/* Show account activation conditionally based on the email confirmation status */}
          {!planData.user?.emailConfirmed && planData.user?.id && (
            <ClaimAccountArea userId={planData.user.id} />
          )}
          <Button
            onClick={() => {
              navigate(`/dashboard/members/${planData.user.id}`);
            }}
            title='View Member Details'
            wFull={false}
            className='px-6'
          />

          {/* Show button to property details if set */}
          {planData?.property && (
            <Button
              onClick={() => {
                navigate(`/dashboard/properties/${planData?.property?.id}`);
              }}
              title='View Property Details'
              wFull={false}
              className='px-6'
            />
          )}

          {/* Impersonate User Button */}
          <Button
            disabled={isImpersonateLoading}
            onClick={() => {
              impersonateTenant({ id: planData.user.id })
                .unwrap()
                .then(({ token }) => {
                  let url = `${process.env.REACT_APP_IMPERSONATION_URL}?token=${token}&planId=${planData.id}`;
                  window.open(url, '_blank');
                });
              /* location.href = */
              /* navigate(`/dashboard/plans/new?userID=${tenantData.id}`); */
            }}
            theme='secondary'
            title='Log In As User'
            wFull={false}
            className='px-6'
          />
        </div>

        {/* Progress Bar */}
        {planData?.status !== statusEnum.active && (
          <ProgressBar>
            <ProgressBarStep
              title='Property Selected'
              complete={planData?.property}
            />
            <ProgressBarStep title='Member Added' complete={planData?.user} />
            <ProgressBarStep title='Lease Added' complete={planData?.lease} />
            <ProgressBarStep title='Coverage Added' complete={hasCoverage} />
            <ProgressBarStep
              title='Ready for Review'
              complete={planData?.status === statusEnum.review}
            />
          </ProgressBar>
        )}

        {/* Plan Information Table */}
        <InfoCard title='Plan Information'>
          <InfoGrid>
            <InfoField title='Plan Status'>
              <StatusLabel status={planData?.status} />
            </InfoField>
            <InfoField title='Created On'>
              {formatDateToHumanDate(planData?.createdAt)}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        {/* Property Details Table */}
        {planData?.property && (
          <InfoCard title='Property Information'>
            <InfoGrid>
              <InfoField title='Name'>{propertyData?.name}</InfoField>
              <InfoField title='Status'>
                <PropertyStatusLabel status={propertyData?.status} />
              </InfoField>
              <InfoField title='ID' className='col-span-2'>
                {propertyData?.id.toUpperCase()}
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Contact Email'>{propertyData?.email}</InfoField>
              <InfoField title='Contact Phone'>
                {formatPhoneNumber(propertyData?.phone)}
              </InfoField>
              <InfoField title='Website'>{propertyData?.website}</InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Default Rate'>
                {`${(propertyData?.defaultRate * 100).toFixed(2)}%`}
              </InfoField>
              <InfoField title='Risk Score'>
                {`${propertyData?.riskScore || '- '} Risk Score`}
              </InfoField>
              <InfoField title='Property Type'>
                <RentalType rentalType={propertyData?.kind} />
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Street Address 1' className='col-span-2'>
                {propertyData?.address.streetAddress1}
              </InfoField>
              {propertyData?.address.streetAddress2 && (
                <InfoField title='Street Address 2' className='col-span-2'>
                  {propertyData?.address.streetAddress2}
                </InfoField>
              )}
              <InfoField title='City'>{propertyData?.address.city}</InfoField>
              {propertyData?.address.state && (
                <InfoField title='State'>
                  {propertyData?.address.state}
                </InfoField>
              )}
              <InfoField title='Postal Code'>
                {propertyData?.address.postalCode}
              </InfoField>
              <InfoField title='Country'>
                {propertyData?.address.country}
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Notes' className='col-span-4'>
                {propertyData?.notes}
              </InfoField>
            </InfoGrid>
          </InfoCard>
        )}

        {/* Member Details Table */}
        {planData?.user && (
          <InfoCard title='Member Information'>
            <InfoGrid>
              <InfoField title='Name'>
                {planData?.user?.name
                  ? planData?.user?.name
                  : `${planData?.user?.firstName} ${planData?.user?.lastName}`}
              </InfoField>
              <InfoField title='Phone'>
                {formatPhoneNumber(planData?.user?.phone)}
              </InfoField>
              <InfoField title='Email' className='col-span-2'>
                {planData?.user?.email}
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Member Status'>
                <MemberStatusLabel
                  status={planData?.user?.status}
                  size='base'
                />
              </InfoField>
              <InfoField title='ID Verification'>
                <DocumentStatusLabel
                  status={planData?.user?.idVerification}
                  size='base'
                />
              </InfoField>
              <InfoField title='Email Verification'>
                <VerificationStatusLabel
                  status={planData?.user?.emailConfirmed}
                  size='base'
                />
              </InfoField>
              <InfoField title='Phone Verification'>
                <VerificationStatusLabel
                  status={planData?.user?.phoneConfirmed}
                  size='base'
                />
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Job Quit Premeditated'>
                <StatusLabel status={planData?.jobQuitPlans ? 'Yes' : 'No'} />
              </InfoField>
              <InfoField title='Moving Premeditated'>
                <StatusLabel status={planData?.movePlans ? 'Yes' : 'No'} />
              </InfoField>
              <InfoField title='Residency Status'>
                {
                  residencyStatusOptions.find(
                    (status) => status.value === planData?.residencyStatus
                  )?.label
                }
              </InfoField>
              {planData?.residencyStatus !== 'citizenPermanent' && (
                <InfoField title='Visa Expiration Date'>
                  {formatDateToHumanDate(planData?.user?.visaExpiration)}
                </InfoField>
              )}
            </InfoGrid>            
          </InfoCard>
        )}

        {/* Rental History */}
        {planData?.rentalHistory && (
          <InfoCard title='Past 3 Years Rental History'>
            <InfoGrid>
              <InfoField title='Addressed Lived'>
                {planData?.rentalHistory?.addressesLived} addresses
              </InfoField>
              <InfoField title='Termination History'>
                {planData?.rentalHistory?.terminationHistory ? '> 2 early terminations' : '< 2 early terminations'}
              </InfoField>
              <InfoField title='Eviction History'>
                {planData?.rentalHistory?.evictionHistory ? '> 1 eviction' : 'No evictions'}
              </InfoField>
            </InfoGrid>
          </InfoCard>
        )}

        {/* Lease Information */}
        {planData?.lease && (
          <InfoCard title='Lease Information'>
            <InfoGrid>
              <InfoField title='Street Address 1' className='col-span-2'>
                {planData?.lease?.address.streetAddress1}
              </InfoField>
              {planData?.lease?.address.streetAddress2 && (
                <InfoField title='Street Address 2' className='col-span-2'>
                  {planData?.lease?.address.streetAddress2}
                </InfoField>
              )}
              <InfoField title='City'>
                {planData?.lease?.address.city}
              </InfoField>
              {planData?.lease?.address.state && (
                <InfoField title='State'>
                  {planData?.lease?.address.state}
                </InfoField>
              )}
              <InfoField title='Postal Code'>
                {planData?.lease?.address.postalCode}
              </InfoField>
              <InfoField title='Country'>
                {planData?.lease?.address.country}
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Signing Date'>
                {formatDateToHumanDate(planData?.lease?.signDate)}
              </InfoField>
              <InfoField title='Start Date'>
                {formatDateToHumanDate(planData?.lease?.startDate)}
              </InfoField>
              <InfoField title='End Date'>
                {formatDateToHumanDate(planData?.lease?.endDate)}
              </InfoField>
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Landlord Name'>
                {planData?.lease?.landlordName}
              </InfoField>
              <InfoField title='Monthly Rent'>
                {planData?.lease?.monthlyRent ? `${FormatAsCurrency(planData?.lease?.monthlyRent, planData?.lease?.currency)} ${planData?.lease?.currency}` : 'N/A'}
              </InfoField>
              <InfoField title='Furnished?'>
                {planData?.lease?.furnished ? 'Furnished' : 'Unfurnished'}
              </InfoField>
              <InfoField title='Repayable Concessions'>
                {planData?.lease?.concessions?.repayable ? 'Yes' : 'No'}
              </InfoField>
              {planData?.lease?.concessions?.repayable && (
                <>
                  <InfoField title='Concession Discount'>
                    {planData?.lease?.concessions?.discount*100}% Off Rent
                  </InfoField>
                  <InfoField title='Concession Length'>
                    {planData?.lease?.concessions?.months} Months
                  </InfoField>
                </>
              )}
            </InfoGrid> 
          </InfoCard>
        )}

        {/* Uploaded Lease and Termination Policy */}
        {planData?.lease?.document && (
          <InfoCard title='Lease Agreement'>
            <InfoGrid>
              <InfoField title='Lease Verification'>
                <StatusLabel status={planData?.leaseVerification} />
              </InfoField>
              {planData?.lease?.document && (
                <InfoField title='Uploaded Lease' className='col-span-2'>
                  <DocumentPreview
                    label={extractDocumentName(planData?.lease?.document)}
                    url={planData?.lease?.document}
                  />
                </InfoField>
              )}              
            </InfoGrid>
            <InfoGrid>
              <InfoField title='Termination Notice Period'>
                {planData?.lease?.terminationPolicy?.noticePeriod || 'N/A'} Days
              </InfoField>
              <InfoField title='Termination Penalty'>
                {planData?.lease?.terminationPolicy?.penalty ? `${FormatAsCurrency(planData?.lease?.terminationPolicy?.penalty, planData?.lease?.currency)} ${planData?.lease?.currency}` : 'N/A'}
              </InfoField>
              <InfoField title='Lease Buyout Months'>
                {planData?.lease?.terminationPolicy?.buyoutMonths ? `${planData?.lease?.terminationPolicy?.buyoutMonths} months` : 'N/A'}
              </InfoField>
              <InfoField title='Lease Buyout Amount'>
                {planData?.lease?.terminationPolicy?.buyout ? `${FormatAsCurrency(planData?.lease?.terminationPolicy?.buyout, planData?.lease?.currency)} ${planData?.lease?.currency}` : 'N/A'}
              </InfoField>
            </InfoGrid>
          </InfoCard>
        )}

        {/* Coverage Information */}
        {hasCoverage && (
          <InfoCard title='Coverage Information'>
            {hasCancelAnytime && (
              <InfoGrid>
                <InfoField title='Coverage'>
                  <span className='font-semibold'>
                  Cancel Anytime
                  </span>
                </InfoField>
                <InfoField title='Coverage Start Date'>
                  {formatDateToHumanDate(
                    planData?.coverage?.cancelAnytime?.startDate
                  )}
                </InfoField>
                <InfoField title='Coverage End Date' className='col-span-2'>
                  {formatDateToHumanDate(
                    planData?.coverage?.cancelAnytime?.endDate
                  )}
                </InfoField>                  
                <InfoField title='Payment Type'>Onetime</InfoField>
                <InfoField title='Amount'>
                  ${planData?.coverage?.cancelAnytime?.payment?.amount}
                </InfoField>
                <InfoField
                  title='Stripe Link'
                  className='col-span-2'
                  onClick={() => {
                    let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${planData?.coverage?.cancelAnytime?.payment?.stripe?.id}`;
                    window.open(url, '_blank');
                  }}          
                >
                  {planData?.coverage?.cancelAnytime?.payment?.stripe?.id}                               
                </InfoField>                
              </InfoGrid>
            )}
            {hasLeaveAnytime && (
              <InfoGrid>
                <InfoField title='Coverage'>
                  <span className='font-semibold'>
                  Leave Anytime
                  </span>
                </InfoField>
                <InfoField title='Coverage Start Date'>
                  {formatDateToHumanDate(
                    planData?.coverage?.leaveAnytime?.startDate
                  )}
                </InfoField>
                <InfoField title='Coverage End Date' className='col-span-2'>
                  {formatDateToHumanDate(
                    planData?.coverage?.leaveAnytime?.endDate
                  )}
                </InfoField>
                {planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ? (
                  <>
                    <InfoField title='Payment Type'>
                      Onetime
                    </InfoField>
                    <InfoField title='Amount'>
                      ${planData?.coverage?.leaveAnytime?.payment?.onetime?.amount}
                    </InfoField>
                    <InfoField
                      title='Stripe Link'
                      className='col-span-2'
                      onClick={() => {
                        let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}payments/${planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id}`;
                        window.open(url, '_blank');
                      }}          
                    >
                      {planData?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id}                               
                    </InfoField> 
                  </>
                ) : (
                  <>
                    <InfoField title='Payment Type'>
                      Subscription
                    </InfoField>
                    <InfoField title='Amount'>
                      ${planData?.coverage?.leaveAnytime?.payment?.subscription?.amount} /month
                    </InfoField>
                    <InfoField
                      title='Stripe Link'
                      className='col-span-2'
                      onClick={() => {
                        let url = `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}subscriptions/${planData?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id}`;
                        window.open(url, '_blank');
                      }}
                    >
                      {planData?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id}
                    </InfoField>
                  </>
                )}                               
              </InfoGrid>
            )}
          </InfoCard>
        )}
      </div>
    </DashboardWrapperLayout>
  );
};

export default PlanDetails;
