import ModalBgLayout from 'layouts/modal-bg';
import Button from 'common/components/button';
import { Formik } from 'formik';
import { InfoField } from 'common/components/info-card';
import Input from 'components/shared/input';
import { useUpdateClaimMutation, useGetClaimQuery, useAddClaimPaymentMutation, useFindPropertyQuery, useGetPlanQuery, useGetPropertyQuery, useUploadRequestUrlMutation } from 'redux/api/ciosAdminApi/ciosAdminApi';
import { toast } from 'react-toastify';
import { isEmpty } from 'utils/isEmpty';
import SelectInput from 'common/components/select-input';
import FormError from 'components/shared/form-error';
import { toDatePickerFormat } from 'utils/date';
import { useEffect, useState } from 'react';
import { Tag } from '@chakra-ui/react';
import * as Yup from 'yup';
import { claimPaymentPreferenceEnum, paymentMethodEnum } from 'utils/enums';
import { currency } from 'common/utils/enums';
import { paymentMethodOptions, planCurrencyOptions } from 'utils/selectOptions';
import FileUploadInput from 'common/components/file-upload-input';
import DocumentPreview from 'common/components/document-preview';
import { extractDocumentName } from 'common/utils/extractDocumentName';
import { uploadFileToS3 } from 'utils/uploadS3';


const LogPaymentModal = ({ claimId, onCancel }) => {
  const [
    addClaimPayment,
    { isLoading: isAddPaymentLoading, isFetching: isAddPaymentFetching },
  ] = useAddClaimPaymentMutation();

  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  const {
    data: propertyData,
    isError: isPropertyError,
    error: propertyError,
  } = useGetPropertyQuery(claimData?.planId?.property);

  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadRequestUrl] = useUploadRequestUrlMutation();

  const selectOptions = [
    { 
      label: (
        <div className='flex gap-2 justify-between items-center'>
          <div className='font-semibold'>
            {propertyData?.name}
          </div>
          <div>
            <Tag>Property</Tag>
          </div>
        </div>
      ), 
      value: 'property' 
    },
    { 
      label: (
        <div className='flex gap-2 justify-between items-center'>
          <div className='font-semibold'>
            {`${claimData?.userId?.name}` || `${claimData?.userId?.firstName} ${claimData?.userId?.lastName}`}
          </div>
          <div>
            <Tag>Member</Tag>
          </div>
        </div>
      ), 
      value: 'member' 
    },
  ]

  // Logging
  // useEffect(() => {
  //   if (claimData) {
  //     console.log('claimData:', claimData);
  //     console.log('propertyData:', propertyData);
  //   }
  // }, [claimData]);

  return (
    <ModalBgLayout>
      <Formik
        initialValues={{
          amount: claimData?.planId?.lease?.monthlyRent || null,
          date: toDatePickerFormat(new Date()),
          recipient: claimData?.paymentPreference === claimPaymentPreferenceEnum.DIRECT ? 'property' : 'member',
          paymentMethod: paymentMethodEnum.WIRE,
          currency: 'USD',
          receipt: '',
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number().required('Required'),
          date: Yup.date().required('Required'),
          recipient: Yup.string().required('Required'),
          paymentMethod: Yup.string().required('Required'),
          currency: Yup.string().required('Required'),
          receipt: Yup.string().required('Required'),
        })}
        onSubmit={(values) => {
          let submissionValues = {
            claimID: claimId,
            claimPayment: {
              amount: values.amount,
              date: values.date,
              recipient: values.recipient,
              paymentMethod: values.paymentMethod,
              currency: values.currency,
              receipt: values.receipt,
            }
          }

          addClaimPayment(submissionValues)
            .unwrap()
            .then(() => onCancel())
            .catch(() => {
              toast.error('Failed to log payment');
            });
        }}
        >
        {({ 
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <div className='flex flex-col w-full gap-6'>
            {/* icon and texts*/}
            <h3 className='text-2xl font-bold'>
              Log Claim Payment
            </h3>

            <div className='grid grid-cols-2 gap-4'>
              <InfoField title='Amount'>
                <Input
                  type='number'
                  value={values?.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='amount'
                  prefix='$'
                  suffix={values.currency}
                />
                <FormError name='amount' />   
              </InfoField>

              <InfoField title='Currency'>
                <SelectInput
                  options={planCurrencyOptions}
                  placeholder='Select Currency'
                  name='currency'
                  value={planCurrencyOptions.find(
                    (option) => option.value === values.currency
                  )}
                  onChange={(selectedOption) => {
                    setFieldValue('currency', selectedOption.value);
                  }}
                  onBlur={handleBlur}
                />
                <FormError name='currency' />
              </InfoField>

              <InfoField title='Payment Date'>
                <Input
                  type='date'
                  value={values?.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='date'
                />
                <FormError name='date' />
              </InfoField>

              <InfoField title='Payment Method'>
                <SelectInput
                  options={paymentMethodOptions}
                  placeholder='Select Payment Method'
                  name='paymentMethod'
                  value={paymentMethodOptions.find(
                    (option) => option.value === values.paymentMethod
                  )}
                  onChange={(option) => {
                    setFieldValue('paymentMethod', option.value);
                  }}
                  onBlur={handleBlur}
                />
                <FormError name='paymentMethod' />
              </InfoField>
            </div>

            <InfoField title='Recipient'>
              <SelectInput
                options={selectOptions}
                isSearchable={false}
                placeholder='Select option from the list'
                name='recipient'
                value={selectOptions.find(
                  (option) =>
                    option.value === values.recipient
                )}
                onChange={(option) => {
                  setFieldValue(
                    'recipient',
                    option.value || 'property'
                  );
                }}
                onBlur={handleBlur}
              />
              <FormError name='recipient' />
            </InfoField>

            <InfoField title='Payment Receipt'>
              <FileUploadInput
                disableUpload={disableFileUpload}
                isUploadError={uploadStatus.isError}
                isUploadLoading={uploadStatus.isLoading}
                isUploadSuccess={uploadStatus.isSuccess}
                handlePDFClear={() => {
                  setFieldValue('receipt', '');
                }}
                handlePDFUpload={async (file) => {
                  let uploadUrlReq;
                  setUploadStatus({
                    isSuccess: false,
                    isLoading: true,
                    isError: false,
                  });

                  // Get the signing url for uploading to s3
                  try {
                    uploadUrlReq = await uploadRequestUrl({
                      fileName: file.name,
                      contentType: file.type,
                    }).unwrap();
                  } catch (e) {
                    setUploadStatus({
                      isSuccess: false,
                      isError: true,
                      isLoading: false,
                    });
                    toast.error(
                      e?.msg ||
                        e?.message ||
                        'failed to upload receipt'
                    );
                    return;
                  }

                  if (
                    !uploadUrlReq?.data?.uploadUrl ||
                    !uploadUrlReq?.data?.viewUrl
                  ) {
                    setUploadStatus({
                      isSuccess: false,
                      isError: true,
                      isLoading: false,
                    });
                    toast.error('failed to upload receipt');
                  }

                  // Upload the file to s3

                  try {
                    await uploadFileToS3({
                      s3Url: uploadUrlReq?.data?.uploadUrl,
                      file,
                    });

                    // Have to set the uploaded file url on receipt
                    setUploadStatus({
                      isSuccess: true,
                      isError: false,
                      isLoading: false,
                    });
                    setDisableFileUpload(true);
                    setFieldValue(
                      'receipt',
                      uploadUrlReq?.data?.viewUrl
                    );
                    toast.info(
                      uploadUrlReq?.status ||
                        'receipt has been uploaded successfully'
                    );
                  } catch (e) {
                    setUploadStatus({
                      isSuccess: false,
                      isError: true,
                      isLoading: false,
                    });
                    toast.error(
                      e?.msg ||
                        e?.message ||
                        'failed to upload receipt'
                    );
                    return;
                  }
                }}
              />
              <FormError name='receipt' />
            </InfoField>          

            {/* buttons */}
            <div className='flex flex-col items-center w-full gap-4 mt-4'>
              <Button disabled={!isValid || !dirty} onClick={handleSubmit} className='px-6' title='Log Payment' />
              <Button onClick={onCancel} className='px-6' theme='secondary' title='Cancel' />              
            </div>
          </div>
        )}
        </Formik>
    </ModalBgLayout>
  );
};

export default LogPaymentModal;
